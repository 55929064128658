import { PageHeaderWithImage } from "../PageHeaderWithImage";
import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import { HotelType, JobType } from "../../Common";
import Layout from "../Layout";
import VerticalWithAlternateImageAndText from "../VerticalWithAlternateImageAndText";
import GetStartedLight from "../GetStartedLight";
import PortfolioTwoCardsWithImage from "../PortfolioTwoCardsWithImage";
import { PageProps, Script } from "gatsby";
import { GenerateHotelSlug } from "../../Common/Functions/GenerateSlugs";

function randomIntFromInterval(
  min: number,
  max: number,
  except?: number
): number {
  const number = Math.floor(Math.random() * (max - min) + min);
  return number === except ? randomIntFromInterval(min, max, except) : number;
}

export const JobDetail: React.FC<
  PageProps<any, { hotel: HotelType; job: JobType }>
> = ({ pageContext: { hotel, job } }) => {
  const firstImage = randomIntFromInterval(0, hotel.images.length);
  const secondImage = randomIntFromInterval(0, hotel.images.length, firstImage);
  const cards = [];

  // if (hotel.ueberschrift_1) {
  cards.push({
    title: job.name,
    description: job.description,
    imageSrc: hotel.images[firstImage].filename,
  });
  // }

  if (hotel.ueberschrift_1) {
    cards.push({
      description: hotel.kurztext_1,
      title: hotel.ueberschrift_1,
      imageSrc: hotel.images[secondImage].filename,
    });
  }

  // check here wether the component is
  // a hotel
  return (
    <Layout>
      <Script>{`window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }`}</Script>
      <PageHeaderWithImage
        heading={job.name}
        imageSrc={job.image.filename}
        subHeading={hotel.name}
      />
      <VerticalWithAlternateImageAndText
        cards={cards}
        // claim={hotel.claim}
        // description={job.description}
      />
      <GetStartedLight
        onPrimaryLinkClick={() => {
          if (window) {
            (window as any).plausible("JobApplyClick", {
              props: { job: job.name, hotel: hotel.name },
            });
          }
        }}
        subheading="Klingt gut?"
        primaryLinkText="Jetzt bewerben"
        primaryLinkUrl={`mailto:${hotel.mail}?subject=${encodeURIComponent(job.name)}`}
        secondaryLinkText="Andere Stellen"
        secondaryLinkUrl={`/${GenerateHotelSlug(hotel.name)}/jobs`}
      />
    </Layout>
  );
};

export default JobDetail;
